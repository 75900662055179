import React, { useState, useEffect } from "react";
import classNames from "classnames";
import {
  Row,
  Col,
  ButtonGroup,
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
} from "reactstrap";

import api from "../../../../../services/api";
import ChartAreaOneLine from "../../../../../components/ChartAreaOneLine";

export default function ChartTotalCusto() {
  const [bigChartData, setBigChartData] = useState("totalNf");
  const [dataNf, setDataNf] = useState([]);
  const [dataPeso, setDataPeso] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function loadCharts() {
      try {
        setLoading(true);
        const responseNf = await api.get(
          "/dashboard/custo/total-notafiscal-por-mes"
        );

        const responsePeso = await api.get(
          "/dashboard/custo/total-peso-por-mes"
        );

        if (responseNf.status === 200) {
          setDataNf(responseNf.data);
        }

        if (responsePeso.status === 200) {
          setDataPeso(responsePeso.data);
        }
      } catch (error) {
        setLoading(false);
      } finally {
        setLoading(false);
      }
    }
    loadCharts();
  }, []);

  return (
    <Card style={{ boxShadow: "0px 1px 20px 0px rgb(0, 0, 0)" }}>
      <CardHeader>
        <Row>
          <Col>
            {bigChartData === "totalNf" ? (
              <>
                <h4>Valor de nota fiscal transportada</h4>
                <CardTitle>
                  Soma dos valores de notas fiscais embarcadas por mês
                </CardTitle>
              </>
            ) : (
              <>
                <h4>Total de peso embarcado</h4>
                <CardTitle>Soma de peso embarcado por mês em Kg</CardTitle>
              </>
            )}
          </Col>
          <Col sm="6">
            <ButtonGroup
              className="btn-group-toggle float-right"
              data-toggle="buttons"
            >
              <Button
                color="info"
                id="0"
                size="sm"
                tag="label"
                className={classNames("btn-simple", {
                  active: bigChartData === "totalNf",
                })}
                onClick={() => setBigChartData("totalNf")}
              >
                <input defaultChecked name="options" type="radio" />
                <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                  Valor de NF
                </span>
                <span className="d-block d-sm-none">
                  <i className="tim-icons icon-coins" />
                </span>
              </Button>
              <Button
                color="info"
                id="1"
                size="sm"
                tag="label"
                className={classNames("btn-simple", {
                  active: bigChartData === "totalWheight",
                })}
                onClick={() => setBigChartData("totalWheight")}
              >
                <input name="options" type="radio" />
                <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                  Peso Embarcado
                </span>
                <span className="d-block d-sm-none">
                  <i className="tim-icons icon-delivery-fast" />
                </span>
              </Button>
            </ButtonGroup>
          </Col>
        </Row>
      </CardHeader>
      <CardBody>
        {bigChartData === "totalNf" ? (
          <ChartAreaOneLine
            data={dataNf}
            grid="rgba(29, 140, 248, 0.1)"
            lineColor="#1f8ef1"
            dataKey="valor"
            name="Nf Embarcado"
            measure="R$"
            yAxisWidth={100}
            loading={loading}
            idLinha="totalNf"
          />
          
        ) : (
          <ChartAreaOneLine
            data={dataPeso}
            grid="rgba(45,206,137, 0.1)"
            lineColor="#10e211"
            dataKey="peso"
            name="Peso Embarcado"
            measure="KG"
            yAxisWidth={100}
            loading={loading}
            idLinha="totalKg"
          />
        )}
      </CardBody>
    </Card>
  );
}
