import React, { useState, useEffect } from "react";
import { FaWeightHanging } from "react-icons/fa";
import { Row, Col, Card, CardTitle, CardBody, CardFooter } from "reactstrap";

import api from "../../../../../services/api";
import { weightFormat } from "../../../../../utils/weightFormat";
import { formatCurrency } from "../../../../../utils/formatCurrency";
import { unitFormat } from "../../../../../utils/unitFormat";

export default function ChartTotalizadores() {
  const [totalizadores, setTotalizadores] = useState([]);

  useEffect(() => {
    async function loadTotalizadores() {
      try {
        const arrayT = [];

        // media de peso embarcado por cte
        const responseTotalizadores = await api.get(
          "/dashboard/custo/totalizadores"
        );

        if (responseTotalizadores.status === 200) {
          arrayT.push({
            titulo: "Peso",
            subtitulo: "Peso médio por entrega",
            valor: weightFormat(responseTotalizadores.data.pesoMedio),
            icon: (
              <div className="info-icon text-center icon-warning">
                <i className="fas weight-hanging">
                  <FaWeightHanging icon="weight-hanging" />
                </i>
              </div>
            ),
          });
          arrayT.push({
            titulo: "Valor de NF",
            subtitulo: "Valor médio por entrega",
            valor: formatCurrency(responseTotalizadores.data.valorMedio),
            icon: (
              <div className="info-icon text-center icon-primary">
                <i className="tim-icons icon-money-coins" />
              </div>
            ),
          });
          arrayT.push({
            titulo: "Frete",
            subtitulo: "Frete médio por entrega",
            valor: formatCurrency(responseTotalizadores.data.freteMedio),
            icon: (
              <div className="info-icon text-center icon-success">
                <i className="tim-icons icon-delivery-fast" />
              </div>
            ),
          });
          arrayT.push({
            titulo: "Entregas",
            subtitulo: "Qtd média de entregas/dia",
            valor: unitFormat(responseTotalizadores.data.entregaMedia),
            icon: (
              <div className="info-icon text-center icon-danger">
                <i className="tim-icons icon-paper" />
              </div>
            ),
          });
        }

        setTotalizadores(arrayT);
      } catch (error) {
        setTotalizadores([]);
      }
    }
    loadTotalizadores();
  }, []);

  return (
    <Row>
      {totalizadores.map((totalizador, index) => (
        <Col lg="3" md="6" key={index.toString()}>
          <Card
            style={{ boxShadow: "0px 1px 20px 0px rgb(0, 0, 0)" }}
            className="card-stats"
          >
            <CardBody>
              <Row>
                <Col xs="4">
                  <div className="info-icon text-center icon-warning">
                    {totalizador.icon}
                  </div>
                </Col>
                <Col xs="8">
                  <div className="numbers">
                    <CardTitle tag="h4">{totalizador.titulo}</CardTitle>
                    <CardTitle tag="h3">{totalizador.valor}</CardTitle>
                  </div>
                </Col>
              </Row>
            </CardBody>
            <CardFooter>
              <hr />
              <div className="stats">
                <i className="tim-icons icon-sound-wave" />
                {totalizador.subtitulo}
              </div>
            </CardFooter>
          </Card>
        </Col>
      ))}
    </Row>
  );
}
