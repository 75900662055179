import React, { useState, useEffect } from "react";
import { Row, Col, Card, CardTitle, CardBody, CardFooter } from "reactstrap";

import api from "../../../../../services/api";
import { formatCurrency } from "../../../../../utils/formatCurrency";
import { unitFormat } from "../../../../../utils/unitFormat";

export default function ChartMediaMensal() {
  const [totalizadores, setTotalizadores] = useState([]);

  useEffect(() => {
    async function loadTotalizadores() {
      try {
        const arrayT = [];

        // media de valores por mes
        const responseTotalizadores = await api.get(
          "/dashboard/custo/media-mensal"
        );

        if (responseTotalizadores.status === 200) {
          arrayT.push({
            titulo: "Nota Fiscal",
            subtitulo: "Média nos últimos 3 meses",
            valor: unitFormat(responseTotalizadores.data.mensalNfe),
            icon: (
              <div className="info-icon text-center icon-warning">
                <i className="tim-icons icon-paper" />
              </div>
            ),
          });
          arrayT.push({
            titulo: "CTe",
            subtitulo: "Média nos últimos 3 meses",
            valor: unitFormat(responseTotalizadores.data.mensalCte),
            icon: (
              <div className="info-icon text-center icon-primary">
                <i className="tim-icons icon-bus-front-12" />
              </div>
            ),
          });
          arrayT.push({
            titulo: "Fatura",
            subtitulo: "Média nos últimos 3 meses",
            valor: unitFormat(responseTotalizadores.data.mensalFatura),
            icon: (
              <div className="info-icon text-center icon-success">
                <i className="tim-icons icon-credit-card" />
              </div>
            ),
          });
          arrayT.push({
            titulo: "Frete",
            subtitulo: "Média nos últimos 3 meses",
            valor: formatCurrency(responseTotalizadores.data.mensalFrete),
            icon: (
              <div className="info-icon text-center icon-danger">
                <i className="tim-icons icon-money-coins" />
              </div>
            ),
          });
        }

        setTotalizadores(arrayT);
      } catch (error) {
        setTotalizadores([]);
      }
    }
    loadTotalizadores();
  }, []);

  return (
    <Row>
      {totalizadores.map((totalizador, index) => (
        <Col lg="3" md="6" key={index.toString()}>
          <Card
            style={{ boxShadow: "0px 1px 20px 0px rgb(0, 0, 0)" }}
            className="card-stats"
          >
            <CardBody>
              <Row>
                <Col xs="4">
                  <div className="info-icon text-center icon-warning">
                    {totalizador.icon}
                  </div>
                </Col>
                <Col xs="8">
                  <div className="numbers">
                    <CardTitle tag="h4">{totalizador.titulo}</CardTitle>
                    <CardTitle tag="h3">{totalizador.valor}</CardTitle>
                  </div>
                </Col>
              </Row>
            </CardBody>
            <CardFooter>
              <hr />
              <div className="stats">
                <i className="tim-icons icon-chart-pie-36" />
                {totalizador.subtitulo}
              </div>
            </CardFooter>
          </Card>
        </Col>
      ))}
    </Row>
  );
}
