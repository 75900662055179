import React, { useState, useEffect, useCallback } from "react";
import { ClipLoader } from "react-spinners";
import { PieChart, Pie, Legend, Cell } from "recharts";
import {
  Row,
  Col,
  Button,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
} from "reactstrap";
import moment from "moment";

import api from "../../../../../services/api";
import ModalFilters from "../../../../../components/CustomModalFilters";

const COLORS = ["#008000", "#FFBB28"];

export default function ChartStatusDiv() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [modalFilters, setModalFilters] = useState(false);
  const [dataInicial, setDataInicial] = useState(
    moment().subtract(90, "days").startOf("month")
  );
  const [dataFinal, setDataFinal] = useState(moment().endOf("month"));
  const [auxDtInicial, setAuxDtInicial] = useState(
    moment().subtract(90, "days").startOf("month")
  );
  const [auxDtFinal, setAuxDtFinal] = useState(moment().endOf("month"));

  useEffect(() => {
    async function loadCharts() {
      try {
        setLoading(true);
        const response = await api.get("/dashboard/custo/status_div", {
          params: {
            dataInicial: auxDtInicial.format("YYYY-MM-DD 00:00:00"),
            dataFinal: auxDtFinal.format("YYYY-MM-DD 23:59:59"),
          },
        });

        if (response.status === 200) {
          setData(response.data);
        }
      } catch (error) {
        setLoading(false);
      } finally {
        setLoading(false);
      }
    }
    loadCharts();
  }, [auxDtInicial, auxDtFinal]);

  const handleDateInicial = (e) => {
    setDataInicial(e);
  };

  const handleDateFinal = (e) => {
    setDataFinal(e);
  };

  const toggleModalFilters = () => {
    setModalFilters(!modalFilters);
  };

  const onSubmit = useCallback(() => {
    setAuxDtInicial(dataInicial);
    setAuxDtFinal(dataFinal);
    setModalFilters(!modalFilters);
  }, [dataFinal, dataInicial, modalFilters]);

  return (
    <Card
      style={{ boxShadow: "0px 1px 20px 0px rgb(0, 0, 0)" }}
      className="card-chart"
    >
      <ModalFilters
        open={modalFilters}
        toggle={toggleModalFilters}
        handleDateInicial={handleDateInicial}
        dataInicial={dataInicial}
        handleDateFinal={handleDateFinal}
        dataFinal={dataFinal}
        onSubmit={() => onSubmit()}
      />
      <CardHeader>
        <Row style={{ alignItems: "center" }}>
          <Col>
            <h4>Status de divergência do CTe</h4>
          </Col>
          <Col style={{ textAlign: "right" }}>
            <Button
              className="btn-sm"
              color="info"
              onClick={toggleModalFilters}
            >
              Período
            </Button>
          </Col>
        </Row>
        <CardTitle>Status do CTe com relação à Divergência</CardTitle>
      </CardHeader>
      <CardBody>
        {loading ? (
          <div style={{ textAlign: "center" }}>
            <ClipLoader
              sizeUnit="px"
              size={90}
              color="#1d8cf8"
              loading={loading}
            />
            <br />
            <br />
            <h1 className="text-info" style={{ textAlign: "center" }}>
              Carregando ...
            </h1>
          </div>
        ) : (
          <Row className="justify-content-center">
            <PieChart
              width={400}
              height={300}
              margin={{ top: 5, right: 5, bottom: 5, left: 5 }}
            >
              <Legend />
              <Pie
                data={data}
                dataKey="value"
                nameKey="name"
                cx="50%"
                cy="50%"
                innerRadius={70}
                outerRadius={110}
                label
                paddingAngle={5}
              >
                {data.map((entry, index) => (
                  <Cell
                    key={index.toString()}
                    fill={COLORS[index % COLORS.length]}
                  />
                ))}
              </Pie>
            </PieChart>
          </Row>
        )}
      </CardBody>
    </Card>
  );
}
