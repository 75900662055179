import React, { useState, useEffect } from "react";
import { ClipLoader } from "react-spinners";
import {
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  Input,
  Collapse,
  Button,
} from "reactstrap";
import "../../../../../assets/css/black-dashboard-pro-react.css";
import api from "../../../../../services/api";
import { useTheme } from "../../../../../hooks/theme";
import { formatCurrency } from "../../../../../utils/formatCurrency";
import { formatDate } from "../../../../../utils/formatDate";

const KanbanCard = ({ fatura, toggleCard, openCard }) => {
  const { darkMode } = useTheme();

  return (
    <Card className="mb-2 kanban-card">
      <CardHeader
        onClick={() => toggleCard(fatura.id)}
        className="kanban-card-header"
        style={{ cursor: "pointer" }}
      >
        <h5 className="card-title">
          <strong>{fatura.Transportadora}</strong>
        </h5>
      </CardHeader>
      <CardBody className="kanban-card-body">
        <p>
          <strong>Fatura:</strong> {fatura.NumeroFat}
        </p>
        <p>
          <strong>Vencimento:</strong> {formatDate(fatura.DataVctoFatura)}
        </p>
        <p>
          <strong>Valor:</strong> {formatCurrency(fatura.ValorFat)}
        </p>
        <Collapse isOpen={openCard === fatura.id}>
          <p>
            <strong>Log:</strong> {fatura.log}
          </p>
        </Collapse>
      </CardBody>
    </Card>
  );
};

const KanbanColumn = ({ columnName, faturas, toggleCard, openCard }) => {
  return (
    <div className="kanban-column">
      <h4 className="kanban-title">{columnName}</h4>
      {faturas.map((fatura) => (
        <KanbanCard
          key={fatura.id}
          fatura={fatura}
          toggleCard={toggleCard}
          openCard={openCard}
        />
      ))}
    </div>
  );
};

const KanbanStatusFatura = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(false);
  const [openCard, setOpenCard] = useState(null);
  const [data, setData] = useState([]);

  useEffect(() => {
    async function loadFaturas() {
      try {
        setLoading(true);
        const response = await api.get("/dashboard/auditoria/statusfatura");
        if (response.status === 200) {
          console.log("API Response:", response.data); // Log da resposta da API
          setData(response.data);
        }
      } catch (error) {
        console.error("Failed to fetch faturas", error);
      } finally {
        setLoading(false);
      }
    }
    loadFaturas();
  }, []);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleClearSearch = () => {
    setSearchTerm("");
  };

  const toggleCard = (id) => {
    setOpenCard(openCard === id ? null : id);
  };

  const statuses = {
    "Falta CTe": "FaltaCTe",
    "Falta Viagem": "FaltaNFe",
    "Fatura Integrada": "FaturaIntegrada",
    Divergência: "FaturaComDivergencia",
    "Fatura Liberada": "FaturaLiberada",
    "Enviada OBC": "FaturaPaga",
  };

  const filteredData = data.filter((fatura) => {
    if (fatura.NumeroFat === null) {
      console.warn(
        `Found a fatura with null NumeroFat: ${JSON.stringify(fatura)}`
      );
      return false;
    }
    return fatura.NumeroFat.toString().includes(searchTerm);
  });

  return (
    <div className="content">
      <Card>
        <CardHeader>
          <Row style={{ alignItems: "center" }}>
            <Col>
              <h4 className="title">Status da Fatura</h4>
            </Col>
            <Col style={{ textAlign: "right" }}>
              <Input
                type="text"
                placeholder="Pesquisar pelo número da fatura"
                value={searchTerm}
                onChange={handleSearchChange}
                style={{
                  display: "inline",
                  width: "auto",
                  marginRight: "10px",
                }}
              />
              <Button className="btn btn-warning" onClick={handleClearSearch}>
                Limpar
              </Button>
            </Col>
          </Row>
        </CardHeader>
        <CardBody>
          {loading ? (
            <div style={{ textAlign: "center" }}>
              <ClipLoader
                sizeUnit="px"
                size={90}
                color="#1d8cf8"
                loading={loading}
              />
              <br />
              <br />
              <h1 className="text-info">Carregando ...</h1>
            </div>
          ) : (
            <div
              className="kanban-container"
              style={{ display: "flex", overflowX: "auto", padding: "20px 0" }}
            >
              {Object.entries(statuses).map(([columnName, statusFilter]) => (
                <KanbanColumn
                  key={columnName}
                  columnName={columnName}
                  faturas={filteredData.filter(
                    (fatura) => fatura.StatusFatura === statusFilter
                  )}
                  toggleCard={toggleCard}
                  openCard={openCard}
                />
              ))}
            </div>
          )}
        </CardBody>
      </Card>
    </div>
  );
};

export default KanbanStatusFatura;
