import React from "react";
import { AuthProvider } from "./auth";
import { ToastProvider } from "./toast";
import { CompaniesProvider } from "./companies";
import { ThemeProvider } from "./theme";

const AppProvider = ({ children }) => (
  <AuthProvider>
    <ThemeProvider>
      <CompaniesProvider>
        <ToastProvider>{children}</ToastProvider>
      </CompaniesProvider>
    </ThemeProvider>
  </AuthProvider>
);

export default AppProvider;
